const html = document.querySelector('html');
const body = document.body;

/*
 * Toggle mobile menu when clicked
 */
const toggleMenu = (toggle, menu) => {
	if (toggle) {
		toggle.addEventListener('click', () => {
			// Disallow user to scroll when menu is open
			if (menu.classList.contains('active')) {
				body.style.overflow = '';
			} else {
				body.style.overflow = 'hidden';
			}
			// Toggle menu mobile visibility
			menu.classList.toggle('active');
			// Set correct ARIA attribute
			if (menu.getAttribute('aria-hidden') === 'true') {
				menu.setAttribute('aria-hidden', 'false');
			} else {
				menu.setAttribute('aria-hidden', 'true');
			}
		});
	}

	// Hide menu mobile when page is resized
	const mediaQuery = window.matchMedia('(min-width: 768px)');
	const hideMenu = (mediaQuery) => {
		if (mediaQuery.matches) {
			menu.classList.remove('active');
			body.style.overflow = '';
		}
	};
	mediaQuery.addListener(hideMenu);
};

/*
 * Update HTML tag with correct language
 */
const updateLang = (languagesLinks) => {
	languagesLinks.forEach((link) => {
	const language = link.getAttribute('hreflang');
	link.addEventListener('click', () => {
			html.setAttribute('lang', language);
		});
	});
};

export {toggleMenu, updateLang};
