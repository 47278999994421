// Barba
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
// Lazysizes
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
// Flickity
import Flickity from 'flickity';
// Custom
import * as Menu from './js/Menu';

lazySizes.cfg.lazyClass = 'lazyload';

// Use prefetch module
barba.use(barbaPrefetch);

// Barba hooks
barba.hooks.beforeEnter((data) => {
  const currentContainer = data.next.container;
  // Go back to top when switching pages
  window.scrollTo(0, 0);
  // Instantiate Menu mobile
  const menuButton = currentContainer.querySelector('.menu-button');
  const menuMobile = currentContainer.querySelector('.menu-mobile');
  Menu.toggleMenu(menuButton, menuMobile);
  // Update HTML tag with correct language
  const languagesLinks = currentContainer.querySelectorAll('.languages-link a');
  Menu.updateLang(languagesLinks);
});

barba.hooks.afterLeave((data) => {
  // Reset body overflow when switching page
  document.body.style.overflow = '';
});

// Initialize Barba
barba.init({
  transitions: [{
    once(data) {
      // Play the fading animation at first page load
      data.next.container.querySelector('.barba-content').classList.add('enter');
    },
    enter(data) {
      // Wait 1 frame before adding the class
      setTimeout(() => data.next.container.querySelector('.barba-content').classList.add('enter'), 1);
    }
  }],
  views: [
    {
      namespace: 'project',
      beforeEnter(data) {
        const currentContainer = data.next.container;
        // Open links in project description in another tab
        const projectDescription = currentContainer.querySelector('.project-description');
        const projectDescriptionLinks = projectDescription.querySelectorAll('a');
        projectDescriptionLinks.forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener');
          link.classList.add('external-link');
        });
      },
      afterEnter(data) {
        const currentContainer = data.next.container;
        const carousel = currentContainer.querySelector('.project-carousel');

        // Flickity
        const flickity = new Flickity(carousel, {
          wrapAround: true,
          watchCSS: true,
          // fullscreen: true,
        });

        flickity.reloadCells();
        carousel.focus();
        // Fix automatic page repositioning after focusing gallery
        window.scrollTo(0, 0);

        function resizeFlickity() {
          const flickityViewport = document.querySelector('.flickity-viewport');

          if (window.innerHeight >= 600) {
            flickityViewport.style.height = '75vh';
          } else {
            flickityViewport.style.height = '500px';
          }
        }

        window.addEventListener('resize', function () {
          resizeFlickity();
        }, true);

        // Update Counter on slide change
        const carouselCounter = currentContainer.querySelector('.project-carousel-counter');
        carouselCounter.innerHTML = '1/' + flickity.cells.length;
        flickity.on('change', function (index) {
          carouselCounter.innerHTML = (index + 1) + '/' + flickity.cells.length;
        });

        // Disable Prev/Next buttons when cell is a video
        const prevNextButton = document.querySelectorAll('.flickity-button');
        flickity.on('change', function (index) {
          if (flickity.selectedElement
            && flickity.selectedElement.classList.contains('project-carousel-cell-video')) {
            prevNextButton.forEach((btn) => {
              btn.style.zIndex = '-1';
            });
          } else {
            prevNextButton.forEach((btn) => {
              btn.style.zIndex = '1';
            });
          }
        });
      }
    }
  ]
});
